import styled from 'styled-components'
import './sub_comp_06_submit.css'
import { Grid, TextField } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { submit_user_info } from 'modules/api/market-api'



const styles = (theme:any) => ({
    multilineColor:{
        color:'red'
    }
});

const Sub_comp_06_submit = (props: any) => {

    const click_submit_user_info = () => {

        try {
            (async () => { 
                // console.log('api: submit_user_info ... ')

                let user_info_result:any = await submit_user_info()

                // console.log(user_info_result)
            })()
        } catch ( e ) {
            console.log(e)
            return 
        }
    }

    return (
    <>
        <div className='sub_06_container_wrapper'>
            <div className='sub_06_whats_inside'>
               <div className='sub_06_wpb_row_vc_fluid'>
                   <CustomGrid container spacing={0} className='sub_06_row_col_wrap_12'>
                        
                        <CustomGrid item xs={12} sm={6} className='sub_06_vc_col_6_grid'>
                            <div className='sub_06_vc_column_inner'>
                                <div className='sub_06_inner'>
                                    <div className='sub_06_hover_wrap_inner'>
                                        <img 
                                            loading="lazy" 
                                            decoding="async" 
                                            className="img-with-animation skip-lazy" 
                                            data-delay="0" 
                                            height="2560" 
                                            width="1707" 
                                            data-animation="none" 
                                            src={require('assets/headless_img_02.png').default}
                                            // src="https://craftsburysoftware.com/wp-content/uploads/2024/07/iam_os-eWx2IEt36So-unsplash-scaled.jpg" 
                                            alt="" 
                                            sizes="(max-width: 1707px) 100vw, 1707px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </CustomGrid>

                        <CustomGrid item xs={12} sm={6} className='sub_06_vc_col_6_grid'>
                            {/* <div className='sub_06_vc_column_inner'>
                                <div className='sub_06_data_tf_live'>
                                    <div className='sub_06_content_form_div'>
                                        <div className='sub_06_root_sc_sc'>
                                            <div>
                                                <div className='sub_06_question_heading'>
                                                    <span>
                                                        Download the <strong>“Best Headless CMS Platform Report”</strong> to compare top vendors and uncover the optimal buying strategy.This question is required.*
                                                    </span>
                                                </div>

                                                <div className='sub_06_spacewrapper'>

                                                    <div className="rvp_pl_5_mr_1">
                                                        <div className='rvp_field_wrapper'>
                                                            <div className='rvp_flex_col'>
                                                                <div className='rvp_col_label_div'>
                                                                    <p style={{margin: 0}}>
                                                                    First name
                                                                    <span 
                                                                        className="fillout-required-asterisk" 
                                                                        aria-label="Required question" 
                                                                        title="Required question" 
                                                                        style={{color: '#0445af'}}
                                                                    > *</span>
                                                                    </p>
                                                                </div>
                                                                <div className='rvp_input_field_div'>
                                                                    <input 
                                                                    data-cy="input-component" 
                                                                    type="text" 
                                                                    className="rvp_input_field_text" 
                                                                    placeholder="Jane" 
                                                                    aria-label="First Name" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rvp_pl_5_mr_1">
                                                        <div className='rvp_field_wrapper'>
                                                            <div className='rvp_flex_col'>
                                                                <div className='rvp_col_label_div'>
                                                                    <p style={{margin: 0}}>
                                                                    Last name
                                                                    <span 
                                                                        className="fillout-required-asterisk" 
                                                                        aria-label="Required question" 
                                                                        title="Required question" 
                                                                        style={{color: '#0445af'}}
                                                                    > *</span>
                                                                    </p>
                                                                </div>
                                                                <div className='rvp_input_field_div'>
                                                                    <input 
                                                                    data-cy="input-component" 
                                                                    type="text" 
                                                                    className="rvp_input_field_text" 
                                                                    placeholder="Smith" 
                                                                    aria-label="First Name" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rvp_pl_5_mr_1">
                                                        <div className='rvp_field_wrapper'>
                                                            <div className='rvp_flex_col'>
                                                                <div className='rvp_col_label_div'>
                                                                    <p style={{margin: 0}}>
                                                                    Phone Number
                                                                    <span 
                                                                        className="fillout-required-asterisk" 
                                                                        aria-label="Required question" 
                                                                        title="Required question" 
                                                                        style={{color: '#0445af'}}
                                                                    > *</span>
                                                                    </p>
                                                                </div>
                                                                <div className='rvp_input_field_div'>
                                                                    <PhoneInput
                                                                        country={"us"}
                                                                        // disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rvp_pl_5_mr_1">
                                                        <div className='rvp_field_wrapper'>
                                                            <div className='rvp_flex_col'>
                                                                <div className='rvp_col_label_div'>
                                                                    <p style={{margin: 0}}>
                                                                    Email
                                                                    <span 
                                                                        className="fillout-required-asterisk" 
                                                                        aria-label="Required question" 
                                                                        title="Required question" 
                                                                        style={{color: '#0445af'}}
                                                                    > *</span>
                                                                    </p>
                                                                </div>
                                                                <div className='rvp_input_field_div'>
                                                                    <input 
                                                                    data-cy="input-component" 
                                                                    type="text" 
                                                                    className="rvp_input_field_text" 
                                                                    placeholder="name@example.com" 
                                                                    aria-label="First Name" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rvp_pl_5_mr_1">
                                                        <div className='rvp_field_wrapper'>
                                                            <div className='rvp_flex_col'>
                                                                <div className='rvp_col_label_div'>
                                                                    <p style={{margin: 0}}>
                                                                    Company
                                                                    <span 
                                                                        className="fillout-required-asterisk" 
                                                                        aria-label="Required question" 
                                                                        title="Required question" 
                                                                        style={{color: '#0445af'}}
                                                                    > *</span>
                                                                    </p>
                                                                </div>
                                                                <div className='rvp_input_field_div'>
                                                                    <input 
                                                                    data-cy="input-component" 
                                                                    type="text" 
                                                                    className="rvp_input_field_text" 
                                                                    placeholder="Acme Corporation" 
                                                                    aria-label="First Name" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='sub_06_submit_btn_div'>
                                                        <div className='sub_06_btn_container'>
                                                            <div className='sub_06_btn_wrapper'>
                                                                <div className='sub_06_btn_wrapper_div'>
                                                                    <div className='sub_06_btn_div' onClick={() => {click_submit_user_info()}}>
                                                                        <span className="sub_06_btn_text">Submit</span>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div data-tf-live="01J3P8TXS7043KS7Z5R8QXYSH5" style={{ width: '100%' }}></div>

                        </CustomGrid>

                   </CustomGrid>

                    <div className='sub_06_row_col_wrap_12'>

                    </div>
               </div>
            </div>
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    .sub_06_row_col_wrap_12 {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
    
        margin-left: -20px;
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;
        float: none;
        margin-bottom: 0!important;
        z-index: 10;
        width: auto;
        position: relative;
    
    }

    .sub_06_vc_col_6_grid {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
        margin-left: 0;
        align-items: stretch;
        display: flex;
        float: none;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: none;
        background-position: center;
        position: relative;

        @media (max-width: 800px) {
            padding: 0;
        }
    
    }
`

export default Sub_comp_06_submit