import { SET_CURRENT_PRODUCT } from '../action/action.config'

const initialState = {
    currentProductUrl: "",    
}

export const baseReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_PRODUCT:
            return {
                ...state,
                currentProductUrl: payload.productUrl,
            }
        default:
            return state
    }
}
