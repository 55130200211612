import { combineReducers } from 'redux'
import { baseReducer } from './base.reducer'
import { productReducer } from './product.reducer'
import { gclidReducer } from './gclid.reducer'

const rootReducer = combineReducers({
  base: baseReducer,
  product: productReducer,
  gclid: gclidReducer,
})
export default rootReducer
