import React from "react";
import "./autoplaycarousel.css";
// import { cardDetails } from "./carousel-config";
import CarouselItem from "./CarouselItem";

const cardDetails = [
    {
        imgUrl: require('assets/slider_logos/21_logo_refact.png').default,
        title: "Text 1"
    },

    {
        imgUrl: require('assets/slider_logos/22_logo_proxnet.png').default,
        title: "Text 2"
    },
    {
        imgUrl: require('assets/slider_logos/23logo_wp.png').default,
        title: "Text 3"
    },

    {
        imgUrl: require('assets/slider_logos/24_logo_-react.png').default,
        title: "Text 4"
    },

    {
        imgUrl: require('assets/slider_logos/25_logo_next.png').default,
        title: "Text 5"
    },

    {
        imgUrl: require('assets/slider_logos/26_logo_aws.png').default,
        title: "Text 6"
    },

    {
        imgUrl: require('assets/slider_logos/27_logo_digi.png').default,
        title: "Text 7",
    }
]

export default function AutoplayCarousel() {
  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
      </div>
    </div>
  );
}