import styled from 'styled-components'
import './sub_comp_04_what.css'
import { Grid } from '@mui/material'

const Sub_comp_04_what = (props: any) => {
    const { section3 } = props
    return (
    <>
        <div className='sub_04_container_wrapper'>
            <div ref={section3} className='sub_04_whats_inside'>
                <div className='sub_04_row_col_wrap_12_col'>
                    <div className='sub_04_vc_col_sm_12'>
                        <h2 style={{textAlign: 'center'}}>
                            {/* What People Say: Headless Software Buyer's Guides */}
                            What People Say: Headless CMS Buyer's Guides
                        </h2>
                        <div id="professor_prebid-root" style={{marginBottom: '24px'}}></div>
                    </div>
                </div>
            </div>

            <div className='sub_04_wpb_row_vc_row_fluid'>
                <CustomGrid container spacing={0} className='sub_04_row_col_wrap_12_span'>

                    <CustomGrid item xs={12} sm={4} className='sub_04_vc_col_4_grid'>
                        <div className='sub_04_vc_column_inner'>
                            <div className='sub_04_wpb_wrapper_grid'>

                                <div className='sub_04_nectar_star_rating'>
                                    <div className="sub_04_rate">
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                    </div>
                                </div>

                                <div className='sub_04_img_with_animation_wrap'>
                                    <div className='sub_04_inner_rating'>
                                        <div className='sub_04_hover_wrap_rating'>
                                            <div className='sub_04_hover_wrap_inner_rating'>
                                                <img 
                                                    decoding="async" 
                                                    className="img-with-animation skip-lazy" 
                                                    data-delay="0" 
                                                    height="136" 
                                                    width="300" 
                                                    data-animation="none" 
                                                    src={require('assets/headless_logo_rm_02.png').default}
                                                    alt="" 
                                                    sizes="(max-width: 300px) 100vw, 136px"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_04_wpb_text_column_rating'>
                                    <p>
                                        {/* <span>“We project the global headless software market to reach USD$ 1,629 million by 2027, growing at a CAGR of 22.6% from 2020 to 2027.”</span> */}
                                        <span>“We project the global headless CMS market to reach USD$ 1,629 million by 2027, growing at a CAGR of 22.6% from 2020 to 2027.”</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                    <CustomGrid item xs={12} sm={4} className='sub_04_vc_col_4_grid'>
                        <div className='sub_04_vc_column_inner'>
                            <div className='sub_04_wpb_wrapper_grid'>

                                <div className='sub_04_nectar_star_rating'>
                                    <div className="sub_04_rate">
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                    </div>
                                </div>

                                <div className='sub_04_img_with_animation_wrap'>
                                    <div className='sub_04_inner_rating'>
                                        <div className='sub_04_hover_wrap_rating'>
                                            <div className='sub_04_hover_wrap_inner_rating'>
                                                <img 
                                                    decoding="async" 
                                                    className="img-with-animation skip-lazy" 
                                                    data-delay="0" 
                                                    height="136" 
                                                    width="300" 
                                                    data-animation="none" 
                                                    src={require('assets/headless_logo_cms_02.png').default}
                                                    alt="" 
                                                    sizes="(max-width: 300px) 100vw, 136px"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_04_wpb_text_column_rating'>
                                    <p>
                                        {/* <span>“Headless software platforms are not just keeping pace with modern web architecture — they’re actively driving its evolution, making it easier than ever to deliver sophisticated, high-performing digital experiences.”</span> */}
                                        <span>“Headless CMS platforms are not just keeping pace with modern web architecture — they’re actively driving its evolution, making it easier than ever to deliver sophisticated, high-performing digital experiences.”</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                    <CustomGrid item xs={12} sm={4} className='sub_04_vc_col_4_grid'>
                        <div className='sub_04_vc_column_inner'>
                            <div className='sub_04_wpb_wrapper_grid'>

                                <div className='sub_04_nectar_star_rating'>
                                    <div className="sub_04_rate">
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                        <label title="text"></label>
                                    </div>
                                </div>

                                <div className='sub_04_img_with_animation_wrap'>
                                    <div className='sub_04_inner_rating'>
                                        <div className='sub_04_hover_wrap_rating'>
                                            <div className='sub_04_hover_wrap_inner_rating'>
                                            <img 
                                                loading="lazy" 
                                                decoding="async" 
                                                className="img-with-animation skip-lazy" 
                                                data-delay="0" 
                                                height="136" 
                                                width="300" 
                                                data-animation="none" 
                                                src={require('assets/headless_logo_tech_02.png').default}
                                                alt="" 
                                                sizes="(max-width: 300px) 100vw, 136px"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_04_wpb_text_column_rating'>
                                    <p>
                                        {/* <span>“Any organization that wants to change its web and content infrastructure must consider a headless software solution. It is part of the future for CMSes.”</span> */}
                                        <span>“Any organization that wants to change its web and content infrastructure must consider a headless CMS solution. It is part of the future for CMSes.”</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                </CustomGrid>
            </div>
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    .sub_04_row_col_wrap_12_span {
        margin: 0;
        padding: 0;
        border: 0;

        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 0!important;

        float: none;
        display: flex;
        flex-wrap: wrap;

        z-index: 10;
        width: auto;
        position: relative;
    }

    .sub_04_vc_col_4_grid {
        margin: 0;
        padding: 0;
        border: 0;

        box-sizing: border-box;

        margin-left: 0;
        float: none;
        margin-right: 0;

        padding-left: 20px;
        padding-right: 20px;

        text-align: center;
        box-shadow: none;
        background-position: center;
        position: relative;
        display: block;

        @media (max-width: 800px) {
            padding: 0;
        }
    }
`

export default Sub_comp_04_what