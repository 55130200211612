import React from "react";
import "./autoplaycarousel.css";
// import { cardDetails } from "./carousel-config";
import CarouselItem from "./CarouselItem";

const cardDetails = [
    {
        imgUrl: require('assets/slider_logos/01_logo_webflow.png').default,
        title: "Text 1"
    },

    {
        imgUrl: require('assets/slider_logos/02_logo_strapi.png').default,
        title: "Text 2"
    },
    {
        imgUrl: require('assets/slider_logos/03_logo_wpvip.png').default,
        title: "Text 3"
    },

    {
        imgUrl: require('assets/slider_logos/04_logo_flexpress-04_L.png').default,
        title: "Text 4"
    },

    {
        imgUrl: require('assets/slider_logos/05_logo_wix.png').default,
        title: "Text 5"
    },

    {
        imgUrl: require('assets/slider_logos/06_logo_contento.png').default,
        title: "Text 6"
    },

    {
        imgUrl: require('assets/slider_logos/07_logo_storyblok.png').default,
        title: "Text 7",
    },   

    {
        imgUrl: require('assets/slider_logos/08_logo_wpengine.png').default,
        title: "Text 8",
    },   

    {
        imgUrl: require('assets/slider_logos/09_logo_sanity.png').default,
        title: "Text 9",
    },   

    {
        imgUrl: require('assets/slider_logos/10_logo_contentstack.png').default,
        title: "Text 10",
    }, 
    
    {
        imgUrl: require('assets/slider_logos/11_logo_prismic.png').default,
        title: "Text 11",
    }, 
]

export default function AutoplayCarousel() {
  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
        {cardDetails.map((ele:any, index:number) => {
          return (
            <CarouselItem
              imgUrl={ele.imgUrl}
              imgTitle={ele.title}
              key={index}
            ></CarouselItem>
          );
        })}
      </div>
    </div>
  );
}