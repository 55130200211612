import React from 'react'
import { Switch } from 'react-router'
import { Router, Route } from 'react-router-dom'
import history from '../history'
import { Paths } from './types'
import { NotFound } from './NotFound'
import { RouteContainer } from './style'
import Home from 'modules/home'
import Buyers_guide from 'modules/buyers_guide'
import Profiles from 'modules/profiles'
import SignIn from 'modules/signin/Signin'
import Footer from 'modules/app/components/footer'
import { Header, MobileHeader } from 'modules/app/components/header'

const notFoundRoute: RouteDefinition = {
  path: '*',
  component: NotFound,
  protected: false,
  title: '',
}

export const routes: RouteDefinition[] = [
  {
    path: Paths.root,
    component: Home,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.buyers_guide,
    component: Buyers_guide,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.profiles,
    component: Profiles,
    protected: false,
    redirect: Paths.root,
  },
  {
    path: Paths.admin_signin,
    component: SignIn,
    protected: false,
    redirect: Paths.root,
  },
].concat(notFoundRoute as any) // Ensure that notFound is the last route

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  component?: any
  routes?: RouteDefinition[]
  title?: string
  pathType?: number
}

interface Props {}
interface RoutesProps {}

function getRouteRenderWithAuth(route: RouteDefinition, i: number) {
  return () => <route.component />
}

const Routes: React.FC<Props & RoutesProps> = () => {
  return (
    <Router history={history}>
      <RouteContainer>
        <Header />
        <MobileHeader />
        <Switch>
          {routes.length > 0 &&
            routes.map((route, i) => {
              const render = getRouteRenderWithAuth(route, i)
              const rest = { render }
              
              return <Route key={i} path={route.path} exact {...rest} />
            })}
        </Switch>
        <Footer />
      </RouteContainer>
    </Router>
  )
}

export default Routes
