export enum Paths {
  root = '/',
  buyers_guide = '/buyers-guide',
  profiles = '/profiles',
  admin_signin = '/admin/signin',
  // test = '/test',
  // admin_signin = '/admin/signin',
  // admin_product = '/admin/product',
  // base = '/:product_url',
  // product_by_asin = '/asin/:asin',
  // terms_of_use = '/s_doc/terms_of_use',
  // privacy_policy = '/s_doc/privacy_policy',
  // data = '/admin/data',
  // admin_quiz = '/admin/quiz',
  // admin_quiz_analyze = '/admin/quiz-analyze',
  // admin_quiz_analyze_users = '/admin/quiz-analyze-users',
  // quiz = '/quiz/:quiz_url',
  // quiz_list = '/quiz/list/all',
  // quiz_old = '/quiz/dq/:old_url',
  // privacy_policy = '/privacy-policy',
  // terms_of_use = '/terms-of-use',
}