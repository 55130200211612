import { SET_CURRENT_GCLID_CURRENT_SEARCH } from '../action/action.config'

const initialState = {
    currentGclid: "",  
    currentMsclkid: "",
    currentSearch: ""  
}

export const gclidReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_GCLID_CURRENT_SEARCH:
            return {
                ...state,
                currentGclid: payload.gclid,
                currentMsclkid: payload.msclkid,
                currentSearch: payload.search,
            }
        default:
            return state
    }
}
