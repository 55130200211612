import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from 'shared/styles/theme'
import Routes from './components/routes/Routes'
import setAuthToken from 'logic/utils/setAuthToken'

if(localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken)
}
const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  )
}

export default App
