import { SET_CURRENT_PRODUCT_ASIN } from '../action/action.config'

const initialState = {
    currentProductAsin: "",    
}

export const productReducer = (state = initialState, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SET_CURRENT_PRODUCT_ASIN:
            return {
                ...state,
                currentProductAsin: payload.asin,
            }
        default:
            return state
    }
}
